<template>
  <div class="not_box">
    <div class="not_page">
      <img src="@/assets/images/404.png" alt="" />
    </div>
    <van-button class="btnx" type="primary" @click="backHome">返回首页</van-button>
  </div>
</template>

<script>
export default {
    methods:{
        backHome(){
            this.$router.push('/home')
        }
    }
};
</script>

<style lang="scss" scoped>
.not_box {
    position: relative;
  .not_page {
    width: 100%;
    height: 100vh;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .btnx{
      position: absolute;
      top: 88%;
      left: 50%;
    transform: translate(-44%,-25%);
  }
}
</style>